import { Card } from 'react-bootstrap';

import FilterView from '../../../common/components/FilterView';
import { Outlet } from 'react-router-dom';
import { toggleColumnSettingsDashboard } from '../slices/dashboardSlice';
import { RootState } from '../../../store';
import dataTableBgImg from '../../../../assets/img/illustrations/cornerRight-1.png';
import { useSelector } from 'react-redux';
import { useBlDashName } from '../../../../hooks/useDashName';

const DashboardRoot = () => {
  const dataLoading = useSelector(
    (state: RootState) => state.dashboard.dataLoading
  );
  const grids = useSelector((state: RootState) => state.dataTable.grids);
  const blDashboardName = useBlDashName() 
  const data = useSelector(
    (state: RootState) => state.dashboard.blDashboardList
  );

  return (
    /*     <Card className='w-100 odex-root-component'>
      <FilterView
        showColumnSettings
        toggleColumnSettings={toggleColumnSettingsDashboard}
        showExport
        columnSettingsPersistKey='@odex/maintable/v6'
        uniqueFilterKey='mainDashboard'
        dataLoading={dataLoading}
        //  configViews={grids?.find?.((g) => g?.gridId === 1)?.configGroup || []}
        configViews={
          grids?.find?.((g) => g?.gridName === blDashboardName)?.configGroup ||
          []
        }
        dashboardName=blDashboardName
        gridDef={grids}
        data={data}
      />
      <Card.Body className='p-0'>
        <div
          className='bg-holder bg-card'
          style={{
            backgroundImage: `url(${dataTableBgImg})`,
            borderTopRightRadius: '0.375rem',
            borderBottomRightRadius: '0.375rem',
          }}
        ></div>
        <Outlet />
      </Card.Body>
    </Card> */
    <FilterView
      showColumnSettings
      toggleColumnSettings={toggleColumnSettingsDashboard}
      showExport
      columnSettingsPersistKey='@odex/maintable/v6'
      uniqueFilterKey='mainDashboard'
      dataLoading={dataLoading}
      //  configViews={grids?.find?.((g) => g?.gridId === 1)?.configGroup || []}
      configViews={
        grids?.find?.((g) => g?.gridName === blDashboardName)?.configGroup || []
      }
      dashboardName={blDashboardName}
      gridDef={grids}
      data={data}
    />
    
  );
};

export default DashboardRoot;

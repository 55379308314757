import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';

import { IDataTableColumn } from '../../common/types/dataTable';
import DataTable from '../../common/components/DataTable';
import CardWrapper from '../../common/components/CardWrapper';
import FilterView from '../../common/components/FilterView';
import { useEffect, useState } from 'react';
import {
  IConfigsDefinition,
} from '../../utils/columnConfig';
import { getDashboardData } from '../dashboard/actions/dashboard';
import { useDispatch } from 'react-redux';

import {
  updateColumnDefinition,
  updateColumnDefinitionConfigs,
} from '../dashboard/slices/dashboardSlice';
import { getColumnViews } from '../../common/actions/dataTable';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { toggleColumnSettingsPaymentList } from '../payment/paymentSlice';
import { AESEncrypt } from '../../../encrypt-util';
import moment from 'moment';
import { updateDashbaordName } from '../../common/slices/navbarTop';
import { useAmountFormatter } from '../../../hooks/useAmountFormatter';
import { useGenerateColumnConfig } from '../../../hooks/useGenerateColumnConfig';

const PaymentFund = () => {
  // Packages
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  // States
  const [columns, setColumns] = useState<IDataTableColumn[]>([]);
  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const grids = useSelector(
    (state: RootState) => state.dataTable.paymentFundGrids
  );
  // Selectors
  const data = useSelector(
    (state: RootState) => state.dashboard.paymentFundList
  );
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);
  const selectedConfigId = useSelector(
    (state: RootState) => state.dataTable.selectedConfigId
  );
  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );
  const paymentFundGrids = useSelector(
    (state: RootState) => state.dataTable.paymentFundGrids
  );
  const showColumnSettings = useSelector(
    (state: RootState) => state.payment.showColumnSettings
  );
  const onHideColumnSettings = () => {
    dispatch(toggleColumnSettingsPaymentList(false));
  };
  const filterStateLocal = filterState?.['paymentFund'];

  const findFieldsByConfigId = (selectedConfigId: any, configGroup: any) => {
    const matchedConfig = configGroup?.find(
      (config: any) => config?.userGridId == selectedConfigId
    );

    if (matchedConfig && matchedConfig?.columnConfig) {
      const fields = matchedConfig?.columnConfig
        .filter((column: any) => column?.visible === 'true')
        .map((column: any) => column?.accessor);

      return { fields };
    } else {
      return { fields: [] };
    }
  };
  const configGroup = paymentFundGrids?.[0]?.configGroupGrid;
  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );
  const searchInputValue = useSelector(
    (state: RootState) => state.navbarTop.searchInputValue
  );

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const paymentFundTopSearch = useSelector(
    (state: RootState) => state.navbarTop.paymentFundTopSearch
  );
  const dataCountry = linerDataCountry;
  useEffect(() => {
    if (dataCountry && linerId){
    dispatch(
      getColumnViews({ gridName: 'PAYMENT_FUNDING', country: dataCountry, linerId : linerId })
    );
    loadPaymentFundings(true);
    }
  }, [dataCountry, linerId]);

  useEffect(() => {
    loadPaymentFundings(false);
  }, [currentPage]);
  // const columns = useSelector((state: RootState) => state.dashboard.columns);
  const generateColumnConfig = useGenerateColumnConfig()

  useEffect(() => {
    const paymentRefuncdColumn = paymentFundGrids?.[0]?.columnConfigGrid || '';

    // const configs = generateColumnConfig(paymentRefuncdColumn);
    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(paymentRefuncdColumn))
      );

      setColumns(configs.columns);
      setConfigs(configs);
      dispatch(updateColumnDefinitionConfigs(configs));
      dispatch(updateColumnDefinition(columns));
    } catch (e: any) {
      console.error('Column Error: ', e);
    }
  }, [paymentFundGrids]);
  
  useEffect(()=>{
    dispatch(updateDashbaordName('PAYMENT_FUNDING'));
  },[])

  useEffect(() => {
    loadPaymentFundings(true);
  }, [filterState, location.search, linerId]);

  const loadPaymentFundings = (filterUpdated: boolean) => {
    const SelectedViewFields = findFieldsByConfigId(
      selectedConfigId?.paymentFund,
      configGroup
    );
    if (filterUpdated) setCurrentPage(1);

    const sort = (queryString.parse(location.search)?.sort as string)
      ?.split?.(',')
      ?.join?.(' ');

    if (filterStateLocal) {
      if (Object.keys(filterStateLocal)?.length > 0) {
        const filterApiState: any = {};

        const filterKeys = Object.keys(filterStateLocal);

        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              filterStateLocal?.[k]?.from || '',
              filterStateLocal?.[k]?.to || '',
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === 'string'
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined || undefined;
          }
        }
        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any = {};

          const currentDate = new Date();

          if (paymentFundGrids[0]) {
            let timeFlag = '';
            let timeDiff = 0;
            let dateRange:any = {};

            const newdata = paymentFundGrids[0].configGroup?.find(
              (c: any) => c.userGridId == selectedConfigId?.['paymentFund']
            )?.columnConfigGrid;
            if (newdata) {
              timeFlag = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'pmt_time'
              )?.filterState;
              timeDiff = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'pmt_time'
              )?.timeDiff;
              dateRange = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'pmt_time'
              )?.dateRange;
            }

            if (filterApiState.pmt_time && timeFlag == 'day' && dateRange){
              filterApiState.pmt_time[1] = moment()
              .subtract(Math.abs(dateRange.to), 'days')
              .endOf('day')
              .toISOString()
              // const newDate = new Date(new Date(currentDate.toISOString()).getTime() - (timeDiff * 24 * 60 * 60 * 1000));
              filterApiState.pmt_time[0] = moment()
              .subtract(Math.abs(dateRange.from), 'days')
              .startOf('day')
              .toISOString()
            }
          }

          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }

          if (
            (profileDetails.partnerType === 'customer' &&
              profileDetails.partnerId &&
              paymentFundGrids?.[0]?.whereClause) ||
            profileDetails.partnerType === 'liner'
          ) {
            dispatch(
              getDashboardData({
                gridName: 'PAYMENT_FUNDING',
                payload: {
                  fields: SelectedViewFields?.fields || [],
                  view: 'rYjSjpCDIgwPLXkU/gKJSsStxcdtzYFcNb+SxQXutJI=',
                  filters: !selectedTopSearchFilter?.where ||
                    selectedTopSearchFilter?.where == '' ||
                    searchInputValue.trim() === ''
                    ? finalFilterApiState
                    : {},

                  size: 20,
                  sorts: sort ? [sort] : ['payment_funding_id desc'],
                  page: filterUpdated ? 1 : currentPage,
                },
                dataCountry: dataCountry,
                partnerId:
                  profileDetails.partnerType === 'customer'
                    ? profileDetails.partnerId
                    : null,
                where: paymentFundGrids?.[0]?.whereClause
                  ? AESEncrypt(paymentFundGrids?.[0]?.whereClause)
                  : null,
                parameter:
                  selectedTopSearchFilter?.where !== ''
                    ? {
                        searchParameter: searchInputValue,
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
              })
            );
          }
        }
      } else {
        if (
          (profileDetails.partnerType === 'customer' &&
            profileDetails.partnerId &&
            paymentFundGrids?.[0]?.whereClause) ||
          profileDetails.partnerType === 'liner'
        ) {
          dispatch(
            getDashboardData({
              gridName: 'PAYMENT_FUNDING',
              payload: {
                fields: SelectedViewFields?.fields || [],
                view: 'rYjSjpCDIgwPLXkU/gKJSsStxcdtzYFcNb+SxQXutJI=',
                filters:
                  selectedTopSearchFilter?.where == ''
                    ? paymentFundTopSearch
                    : {},
                size: 20,
                sorts: sort ? [sort] : ['payment_funding_id desc'],
                page: filterUpdated ? 1 : currentPage,
              },
              dataCountry: dataCountry,
              partnerId:
                profileDetails.partnerType === 'customer'
                  ? profileDetails.partnerId
                  : null,
              where: paymentFundGrids?.[0]?.whereClause
                ? AESEncrypt(paymentFundGrids?.[0]?.whereClause)
                : null,
              parameter:
                selectedTopSearchFilter?.where !== ''
                  ? {
                      searchParameter: searchInputValue,
                      searchWhere: selectedTopSearchFilter?.where,
                    }
                  : {},
            })
          );
        }
      }
    } else {
      if (location.pathname.includes('/payment')) {
        if (
          (profileDetails.partnerType === 'customer' &&
            profileDetails.partnerId &&
            paymentFundGrids?.[0]?.whereClause) ||
          profileDetails.partnerType === 'liner'
        ) {
          dispatch(
            getDashboardData({
              gridName: 'PAYMENT_FUNDING',
              payload: {
                fields: SelectedViewFields?.fields || [],
                view: 'rYjSjpCDIgwPLXkU/gKJSsStxcdtzYFcNb+SxQXutJI=',
                filters:
                  selectedTopSearchFilter?.where === ''
                    ? paymentFundTopSearch
                    : {},
                size: 20,
                sorts: sort ? [sort] : ['payment_funding_id desc'],
                page: filterUpdated ? 1 : currentPage,
              },
              dataCountry: dataCountry,
              partnerId:
                profileDetails.partnerType === 'customer'
                  ? profileDetails.partnerId
                  : null,
              where: paymentFundGrids?.[0]?.whereClause
                ? AESEncrypt(paymentFundGrids?.[0]?.whereClause)
                : null,
              parameter:
                selectedTopSearchFilter?.where !== ''
                  ? {
                      searchParameter: searchInputValue,
                      searchWhere: selectedTopSearchFilter?.where,
                    }
                  : {},
            })
          );
        }
      }
    }
  };

  return (
    <CardWrapper>
      <FilterView
        showExport
        data={data}
        // dashboardName='PAYMENT_FUNDING'
        showColumnSettings={true}
        uniqueFilterKey={'paymentFund'}
        toggleColumnSettings={toggleColumnSettingsPaymentList}
        configViews={
          paymentFundGrids?.find?.((g) => g?.gridName === 'PAYMENT_FUNDING')
            ?.configGroup || []
        }
        dashboardName='PAYMENT_FUNDING'
        gridDef={paymentFundGrids}
      />
      {columns.length > 0 && (
        <DataTable
          columns={columns}
          data={data?.result || null}
          pagination
          numberPagination
          maxPage={parseInt(data?.maxPage || '100')}
          hasNext={data?.hasNext}
          currentPage={currentPage}
          showColumnSettings={showColumnSettings}
          onHide={onHideColumnSettings}
          onChangePageNumber={(pageNumber) => setCurrentPage(pageNumber)}
          selection={undefined}
          sortable={undefined}
          perPage={20}
          searchable={false}
          showExport={undefined}
          showColumnFilter={undefined}
          uniqueFilterKey={'paymentFund'}
          defaultHiddenCols={configs?.defaultHiddenCols || []}
          staticHiddenCols={configs?.staticHiddenCols || []}
          gridName='PAYMENT_FUNDING'
          configGroup={
            //  grids?.find?.((g) => g?.gridId === 2)?.configGroup || []
            paymentFundGrids?.find?.((g) => g?.gridName === 'PAYMENT_FUNDING')
              ?.configGroup || []
          }
          gridId={paymentFundGrids?.[0]?.gridId || 0}
          customSort
        />
      )}
    </CardWrapper>
  );
};

export default PaymentFund;

import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NavbarTop from '../components/navbar/top/NavbarTop';
import NavbarVertical from '../components/navbar/vertical/NavbarVertical';
import AppContext from '../context/Context';
import i18n from 'i18next';
import Footer from '../components/footer/Footer';
import ProductProvider from '../components/app/e-commerce/ProductProvider';
import infiniteLoop from '../assets/img/animated-icons/infinite-loop.json';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import {
  getUserGridsDefinition,
  getUserProfile,
  getRbacDetails,
  updateCookie,
} from '../app/common/actions/userDetails';
import { useSelector } from 'react-redux';
import {
  updateLinerCountryList,
  updateLinerDataCountry,
  updateRbacDetails,
  updatelinerGrpCountry,
} from '../app/common/slices/userDetails';
import {
  AESEncrypt,
  AESEncryptforDashboard,
  Base64Encode,
} from '../encrypt-util';
import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import useDataCountry from '../hooks/useDataCountry';
import { clearGrids } from '../app/common/components/DataTable/slices/DataTable';
const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  const profileDetails = useSelector((state) => state.userProfile.profile);
  const showMainLayout = useSelector(
    (state) => state.themeSettings.showMainLayout
  );
  const dataCountry = useDataCountry();
  const loading = useSelector((state) => state.loading.globalLoading);
  const partnerCountry = profileDetails.partnerCountry;

  const linerGrpCountry = useSelector(
    (state) => state.userProfile.linerGrpCountry
  );
  const dispatch = useDispatch();
  const linerId = useSelector((state)=>state.navbarTop.linerId)

  // const isChat = pathname.includes('chat');
  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getRbacDetails());
    dispatch(
      updateCookie({
        key: Base64Encode(AESEncryptforDashboard('locale')),
        value: Base64Encode(
          AESEncryptforDashboard(
            new Intl.DateTimeFormat().resolvedOptions().locale
          )
        ),
      })
    );
    let date = new Date('2024-12-31').toLocaleDateString();
    date = date
      .replaceAll('31', 'dd')
      .replaceAll('12', 'MM')
      .replaceAll('2024', 'yyyy');
    let dateTime = new Date('2024-12-31 23:59:58').toLocaleString();
    dateTime = dateTime
      .replaceAll('31', 'dd')
      .replaceAll('12', 'MM')
      .replaceAll('2024', 'yyyy')
      .replaceAll('23', 'HH24')
      .replaceAll('59', 'MI')
      .replaceAll('58', 'SS')
      .replaceAll('11', 'HH');
    let timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

    dispatch(
      updateCookie({
        key: Base64Encode(AESEncryptforDashboard('date_format')),
        value: Base64Encode(AESEncryptforDashboard(date)),
      })
    );
    dispatch(
      updateCookie({
        key: Base64Encode(AESEncryptforDashboard('time_format')),
        value: Base64Encode(AESEncryptforDashboard(dateTime)),
      })
    );
    dispatch(
      updateCookie({
        key: Base64Encode(AESEncryptforDashboard('time_zone')),
        value: Base64Encode(AESEncryptforDashboard(timezone)),
      })
    );
  }, []);
  const rbac = useSelector(
    (state) => state.userProfile.data?.response?.data?.list
  );
  const linerCountryList = useSelector(
    (state) => state.userProfile.countryList
  );
  const linerDataCountry = useSelector(
    (state) => state.userProfile.linerDataCountry
  );
  useEffect(() => {
    const rbacData2 = [];
    const linerCountryList = [];
    const jsonRbac = rbac?.map((v) => JSON.parse(v?.rbacColumnConfig));
    const countryList = rbac?.map((v) => v?.countryList);
    if (jsonRbac) {
      for (const rbac of jsonRbac) {
        Object.entries(rbac).forEach(([key, val]) => {
          rbacData2[key] = val == 'Y' || rbacData2[key] == 'Y' ? 'Y' : '';
        });
      }
    }
    if (profileDetails?.partnerType == null) {
      dispatch(getUserProfile());
    }
    if (profileDetails?.partnerType == 'liner' || countryList) {
      if (countryList) {
        for (let k of Object(countryList)) {
          dispatch(updateLinerCountryList(k));
        }
      }
      for (let k of Object(rbac?.map((v) => v?.grpCountry))) {
        dispatch(updatelinerGrpCountry(k));
      }
    }

    dispatch(updateRbacDetails(rbacData2));
  }, [rbac]);
  const userSelectedCountry = JSON.parse(
    window.localStorage.getItem('@user-selected-datacountry')
  );
  const selectedCountry =
    userSelectedCountry?.[0]?.loginId === profileDetails?.email
      ? userSelectedCountry?.[0]?.country
      : profileDetails.partnerType === 'customer'
      ? profileDetails.partnerCountry
      : null;
  const userName = profileDetails.username;
  // debugger
  const selectedLinerDataCountry = selectedCountry
    ? selectedCountry
    : linerDataCountry
    ? linerDataCountry
    : linerCountryList
    ? linerCountryList[0]?.countryCode
    : profileDetails.partnerType === 'customer'
    ? profileDetails.partnerCountry
    : null;
  useEffect(() => {
    const storedUserData =
      JSON.parse(window.localStorage.getItem('@stored-user-data')) || [];
    const storedDataCountry =
      JSON.parse(window.localStorage.getItem('@user-selected-dataCountry')) ||
      [];
    if (selectedLinerDataCountry || selectedLinerDataCountry != null) {
      dispatch(updateLinerDataCountry(selectedLinerDataCountry));
      const updateAndFetchCountry = (selectedLinerDataCountry) => {
        const userSelectedCountry = [
          {
            loginId: profileDetails.email,
            country: selectedLinerDataCountry,
          },
        ];
        window.localStorage.setItem(
          '@user-selected-datacountry',
          JSON.stringify(userSelectedCountry)
        );

        // dispatch(getUserGridsDefinition(selectedLinerDataCountry))
        //   .then((data) => {
        //     const updatedUserData = [
        //       ...storedUserData,
        //       { username: userName, country: selectedLinerDataCountry },
        //     ];
        //     window.localStorage.setItem(
        //       '@stored-user-data',
        //       JSON.stringify(updatedUserData)
        //     );
        //   })
        //   .catch((error) => {
        //     // Handle API call error here
        //     console.error('Error fetching user grids definition:', error);
        //   });

        i18n.changeLanguage(selectedLinerDataCountry);
      };
      const currentUserData = {
        username: userName,
        country: selectedLinerDataCountry,
      };

      const findUserIndex = storedUserData.findIndex(
        (user) => user.username === userName
      );

      const isUserLiner = profileDetails.partnerType === 'liner';
      const hasCurrentUserStoredData = !!(
        findUserIndex !== -1 && storedUserData[findUserIndex]?.country
      );

      if (!hasCurrentUserStoredData) {
        let selectedCountry = null;
        if (isUserLiner && selectedLinerDataCountry) {
          selectedCountry = selectedLinerDataCountry;
        } else if (partnerCountry) {
          selectedCountry = partnerCountry;
        }

        if (selectedCountry) {
          updateAndFetchCountry(selectedCountry);
        }
      } else {
        //const storedCountry = storedUserData[findUserIndex].country;
        const storedCountry =
          storedDataCountry.loginId === profileDetails.email
            ? storedDataCountry.country
            : '';
        if (selectedCountry !== storedCountry) {
          updateAndFetchCountry(selectedCountry);
        } else {
          //dispatch(getUserGridsDefinition(storedCountry));
          i18n.changeLanguage(storedCountry);
        }
      }
    }
  }, [
    userName,
    linerId,
    linerDataCountry,
    partnerCountry,
    profileDetails.partnerType,
    selectedLinerDataCountry,
  ]);

  const {
    config: { isFluid, navbarPosition },
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    dispatch(clearGrids());
  }, [dataCountry]);

  useEffect(() => {
    if (!pathname.includes('/user/profile')) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
  const navigate = useNavigate();
  return (
    <div
      className={isFluid ? 'container-fluid' : 'container'}
      style={
        loading
          ? { pointerEvents: 'none', opacity: '50%', overflow: 'hidden' }
          : {}
      }
    >
      {loading && (
        <div
          className='d-flex overflow-hidden'
          style={{
            position: 'absolute',
            zIndex: 99999,
            justifyContent: 'center',
            width: '100vw',
            height: '100vh',
          }}
        >
          <Lottie
            animationData={infiniteLoop}
            loop={true}
            style={{
              height: '20vh',
              width: '20vh',
              alignSelf: 'center',
              justifySelf: 'center',
            }}
          />
        </div>
      )}

      {profileDetails.pwdExpired?.toUpperCase() === 'Y' &&
        navigate('/resetpassword')}

      {showMainLayout &&
        (navbarPosition === 'vertical' || navbarPosition === 'combo') && (
          <NavbarVertical />
        )}
      <ProductProvider>
        <div className={classNames('content', { 'pb-0': isKanban })}>
          {showMainLayout && <NavbarTop />}
          {/*------ Main Routes ------*/}
          <Outlet />
          {/* {!isKanban && <Footer />} */}
        </div>
      </ProductProvider>
    </div>
  );
};

export default MainLayout;

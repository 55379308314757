import { useSelector } from "react-redux";
import { RootState } from "../app/store";

export function useInstDashName(){
    const partner = useSelector((state:RootState)=>state.userProfile.profile.partnerType?.toUpperCase() == 'CUSTOMER')
    return partner?"INSTRUCTION_DASHBOARD_CUSTOMER":"INSTRUCTION_DASHBOARD_LINER"
}


export function useInvDashName(){
    const partner = useSelector((state:RootState)=>state.userProfile.profile.partnerType?.toUpperCase() == 'CUSTOMER')
    return partner?"INVOICE_DASHBOARD_CUSTOMER":"INVOICE_DASHBOARD_LINER"
}

export function useBlDashName(){
    const partner = useSelector((state:RootState)=>state.userProfile.profile.partnerType?.toUpperCase() == 'CUSTOMER')
    return partner?"BL_DASHBOARD_CUSTOMER":"BL_DASHBOARD_LINER"
}
import { useTranslation } from 'react-i18next';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { useEffect } from 'react';
import { faCodeMerge, faMagnifyingGlass, faSave } from '@fortawesome/free-solid-svg-icons';
import i18next from 'i18next';
import { useBackNavigation } from '../../../hooks/BackNavigation';
import useDataCountry from '../../../hooks/useDataCountry';
import AddCustomerCodeList from './MappingCodeSearchList';
import { useDispatch } from 'react-redux';
import { getCodeSearchListData, getMatchCodeListData } from './MappingActions';
import { AppDispatch, RootState } from '../../store';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { clearCodeSearchList, clearMatchCodeList, setAddCodeFilters } from './MappingSlice';
import MatchCodeList from './MappingConfirmMergeList';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';


const AddCodeSlider = () => {
  const { t } = useTranslation();

  const onBack = useBackNavigation();
  const dispatch = useDispatch<AppDispatch>();
  const linerOptions = useSelector((state:RootState)=>state.registration.linerOptions)
  const filterValues = useSelector((state:RootState)=>state.mappingDashboard.addCustomerCodeFilter)
  const tableData = useSelector((state:RootState)=>state.mappingDashboard.codeSearchListData)
  const matchCodeList = useSelector((state:RootState)=>state.mappingDashboard.matchCodeList)
  const {custId} = useParams()

  useEffect(()=>{
    dispatch(clearCodeSearchList())
    dispatch(clearMatchCodeList())
    dispatch(setAddCodeFilters({code: ''}))
  }, [])

  return (
    <Card className='h-100 overflow-auto'>

      <Card.Header className='d-flex align-items-center border-bottom sticky-top bg-white'>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          className='text-warning cursor-pointer fa-lg mb-1 me-1'
          onClick={onBack}
        />
        <h2 className='fs-0 text-primary mb-1'>{'Customer Code Details'}</h2>
      </Card.Header>

      <Card.Body>
        <div className='row'>
          <div className='col-lg-3 mb-3'>
            <Form.Label className='fs--1'>
              {t('resource_1:liner')}
            </Form.Label>
            <Form.Select
              aria-label={t('resource_1:liner')}
              className='fs--1'
              disabled={matchCodeList != null}
              value={filterValues.liner}
              onChange={(e) => {
                dispatch(setAddCodeFilters({liner: e.target.value}))
              }}
            >
              {linerOptions.map((i:any)=><option key={i.value} value={i.value}>{i.text}</option>)}

            </Form.Select>
          </div>

          <div className='col-lg-4 mb-3'>
            <Form.Label className='fs--1'>
              {'Customer Code/ Match Code'}
            </Form.Label>
            <Form.Control
              aria-label={t(t('resource_2:customerCode'))}
              className='fs--1'
              disabled={matchCodeList != null}
              value={filterValues.code}
              onChange={(e) => {
                dispatch(setAddCodeFilters({code: e.target.value}))
              }}
            >
            </Form.Control>
          </div>

          <div className='col-lg-4 mb-3 align-center'>
            <Button
              variant='falcon-primary'
              size='sm'
              style={{ width: 'auto', marginTop: '2rem' }}
              className='me-2'
              disabled={matchCodeList != null}
              onClick={() => {
                if(filterValues.code && filterValues.code != '')
                dispatch(getCodeSearchListData({code:filterValues.code, liner:filterValues.liner}))
                else toast.error("Add Customer / Match Code to filter")
              }}
              >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </Button>
          </div>
        </div>

        <AddCustomerCodeList disabled={matchCodeList != null} />

        <div className='d-flex justify-content-center align-items-center'>
          { !(matchCodeList != null) && <Button
            className='me-2'
            variant='falcon-primary'
            size='sm'
            disabled={
              !tableData?.find((i)=>i.selected) || matchCodeList != null
            }
            onClick={()=>{
              if(tableData?.find((i:any)=>i.selected == true && i.matchCode == null))
              {
                toast.error("Match Code is required to perform Merge !")
                return
              }
              dispatch(getMatchCodeListData({custId}))
            }}
          >
            <FontAwesomeIcon icon={faCodeMerge} className='me-2' style={{color:'#f5822a'}} />
            Merge
          </Button>}
        </div>
        { matchCodeList != null &&
        <MatchCodeList/>
        }
      </Card.Body>

    </Card>
  );
};

export default AddCodeSlider;

import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
import './assets/scss/main.scss';
import './helpers/initFA';
import './assets/scss/main.scss';

import './app/assets/locales';
import Main from './Main';
import Layout from './layouts/Layout';

import { Provider } from 'react-redux';
import { store } from './app/store';

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browsers');
//   worker.start();
// }

// import './axiosSetup';

const newRelicOptions = {
  init: {distributed_tracing:{enabled:true},privacy:{cookies_enabled:true},ajax:{deny_list:["bam.nr-data.net"]}}, // NREUM.init
  info: {beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:"NRJS-614d1a57bab1c0a99e7",applicationID:"601493693",sa:1}, // NREUM.info
  loader_config: {accountID:"4603686",trustKey:"4603686",agentID:"601493693",licenseKey:"NRJS-614d1a57bab1c0a99e7",applicationID:"601493693"} // NREUM.loader_config
}


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Main>
    <Provider store={store}>
      <Router>
        <Layout />
      </Router>
    </Provider>
  </Main>
);

reportWebVitals();
new BrowserAgent(newRelicOptions);

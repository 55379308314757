/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';

import TableComponent from './TableComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDashboardData } from '../../dashboard/actions/dashboard';
import queryString from 'query-string';
import { getColumnViews } from '../../../common/actions/dataTable';
import { updateDashbaordRefresh, updateSortStateDataTable } from '../../../common/components/DataTable/slices/DataTable';
import {
  updateCurrentPageInstructionDashboard,
  updateCurrentPageRegistrationDashboard,
  updatePrveDependenciesBlDashboard,
  updateSetDefaultViewBL,
} from '../../dashboard/slices/dashboardSlice';
import { updateParams } from '../../../common/components/DataTable/slices/DataTable';
import { AESEncrypt } from '../../../../encrypt-util';
import { updateDashbaordName } from '../../../common/slices/navbarTop';
import { getUserProfile } from '../../../common/actions/userDetails';

const MainTable = () => {
  const [previousState, setPreviousState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: null,
    search: null,
    grids: null,
  });
  const location = useLocation();

  const instructionTopSearchState = useSelector(
    (state: RootState) => state.navbarTop.instructionTopSearchState
  );
  const registrationMasterGrid = useSelector(
    (state: RootState) => state.dataTable.registrationMasterGrid
  );
  const dispatch = useDispatch<AppDispatch>();

  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );
  const searchInputValue = useSelector(
    (state: RootState) => state.navbarTop.searchInputValue
  );
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);
  const filterStateLocal = filterState?.['adminDashboard'];

  const sort = (queryString.parse(location.search)?.sort as string)
    ?.split?.(',')
    ?.join?.(' ');

  const setDefaultViewBL = useSelector(
    (state: RootState) => state.registrationDashboard.setDefaultViewBL
  );
  const grids = useSelector(
    (state: RootState) => state.dataTable.registrationMasterGrid
  );
  const currentPage = useSelector(
    (state: RootState) => state.dashboard.currentPageRegistration
  );

  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  useEffect(() => {
    if (!profileDetails.partnerId) {
      dispatch(getUserProfile());
    }
  }, [profileDetails]);

  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const storedCountry = window.localStorage.getItem('@user-selected-country');

  const dataCountry = linerDataCountry
    ? linerDataCountry
    : profileDetails.partnerCountry || null;
  useEffect(() => {
    dispatch(updateDashbaordName('REGISTRATION_DASHBOARD'));
  }, []);
  
  const refresh = useSelector((
    state:RootState)=> state.dataTable.refreshDashboard)
  useEffect(()=>{
    if (refresh) loadRegistrationDashboard(false)
    // server sometimes takes time to update the view so the delay
    setTimeout(()=>dispatch(updateDashbaordRefresh(false)),2000)
  }, [refresh])

  useEffect(() => {
    if (location?.pathname && dataCountry && linerId) {
      dispatch(
        getColumnViews({
          gridName: 'REGISTRATION_DASHBOARD',
          country: dataCountry,
          linerId : linerId
        })
      );
    }
    dispatch(updateParams({}));
    dispatch(
      updateSortStateDataTable({
        key: null,
      })
    );
  }, [dataCountry, linerId]);

  useEffect(() => {
    const configViews = grids?.find?.(
      (g: any) => g?.gridName == 'REGISTRATION_DASHBOARD'
    )?.configGroup;

    if (configViews && configViews?.length > 0 && setDefaultViewBL) {
      dispatch(updateSetDefaultViewBL(false));
    }
  }, []);
  const prevPageRegistrationDashbaord = useSelector(
    (state: RootState) =>
      state.dashboard.prevDependencies.prevPageRegistrationDashbaord
  );
  const setPrevPageRegistrationDashbaord = (state: string) => {
    dispatch(
      updatePrveDependenciesBlDashboard({
        key: 'prevPageRegistrationDashbaord',
        value: state,
      })
    );
  };

  useEffect(() => {
    const currentStateValue = {
      filterState: JSON.stringify(filterStateLocal),
      search: location.search,
      grids: grids,
    };
    if (JSON.stringify(previousState) !== JSON.stringify(currentStateValue)) {
      loadRegistrationDashboard(false);
      const currentState = {
        filterState: JSON.stringify(filterStateLocal),
        search: location.search,
        grids: grids,
      };
      setPreviousState(currentState);
    }
  }, [filterStateLocal, location.search, grids]);

  useEffect(() => {
    if (currentPage && currentPage !== prevPageRegistrationDashbaord) {
      setPrevPageRegistrationDashbaord(currentPage);
      loadRegistrationDashboard(false);
    }
  }, [currentPage]);

  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageRegistrationDashboard(page));
  };
  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );
  const loadRegistrationDashboard = (filterUpdated: boolean) => {
    if (filterUpdated) {
      setCurrentPage(1);
    }

    if (filterStateLocal) {
      if (Object.keys(filterStateLocal)?.length > 0) {
        const filterApiState: any = {};

        const filterKeys = Object.keys(filterStateLocal);

        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              filterStateLocal?.[k]?.from || '',
              filterStateLocal?.[k]?.to || '',
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === 'string'
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined || undefined;
          }
        }
        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any = {};

          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }
          if (
            profileDetails &&
            ((profileDetails.partnerType === 'customer' &&
              profileDetails.partnerId &&
              registrationMasterGrid?.[0]?.whereClause) ||
              profileDetails?.partnerType === 'liner')
          ) {
            dispatch(
              getDashboardData({
                gridName: 'REGISTRATION_DASHBOARD',
                payload: {
                  fields: [],

                  filters: !selectedTopSearchFilter?.where
                    ? finalFilterApiState
                    : {},

                  size: 20,
                  sorts: sort ? [sort] : ['submissiondate desc'],
                  page: filterUpdated ? 1 : currentPage,
                },
                dataCountry: dataCountry,
                partnerId:
                  profileDetails.partnerType === 'customer'
                    ? profileDetails.partnerId
                    : null,
                where: registrationMasterGrid?.[0]?.whereClause
                  ? AESEncrypt(registrationMasterGrid?.[0]?.whereClause)
                  : null,
                parameter:
                  selectedTopSearchFilter?.where !== ''
                    ? {
                        searchParameter: searchInputValue,
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
              })
            );
          }
        }
      } else {
        if (
          profileDetails &&
          ((profileDetails.partnerType === 'customer' &&
            profileDetails.partnerId &&
            registrationMasterGrid?.[0]?.whereClause) ||
            profileDetails?.partnerType === 'liner')
        ) {
          dispatch(
            getDashboardData({
              gridName: 'REGISTRATION_DASHBOARD',
              payload: {
                fields: [],
                filters:
                  selectedTopSearchFilter?.where == ''
                    ? instructionTopSearchState
                    : {},

                size: 20,
                sorts: sort ? [sort] : ['submissiondate desc'],
                page: filterUpdated ? 1 : currentPage,
              },
              dataCountry: dataCountry,
              partnerId:
                profileDetails.partnerType === 'customer'
                  ? profileDetails.partnerId
                  : null,
              where: registrationMasterGrid?.[0]?.whereClause
                ? AESEncrypt(registrationMasterGrid?.[0]?.whereClause)
                : null,
              parameter:
                selectedTopSearchFilter?.where !== ''
                  ? {
                      searchParameter: searchInputValue,
                      searchWhere: selectedTopSearchFilter?.where,
                    }
                  : {},
            })
          );
        }
      }
    } else {
      if (
        //  !location.pathname.includes('blsliderinstruction/') &&
        location.pathname.includes('/registrationmaster')
      ) {
        if (
          profileDetails &&
          ((profileDetails.partnerType === 'customer' &&
            profileDetails.partnerId &&
            registrationMasterGrid?.[0]?.whereClause) ||
            profileDetails?.partnerType === 'liner')
        ) {
          dispatch(
            getDashboardData({
              gridName: 'REGISTRATION_DASHBOARD',
              payload: {
                fields: [],

                filters:
                  selectedTopSearchFilter?.where == ''
                    ? instructionTopSearchState
                    : {},

                size: 20,
                sorts: sort ? [sort] : ['submissiondate desc'],
                page: filterUpdated ? 1 : currentPage,
              },
              dataCountry: dataCountry,
              partnerId:
                profileDetails.partnerType === 'customer'
                  ? profileDetails.partnerId
                  : null,
              where: registrationMasterGrid?.[0]?.whereClause
                ? AESEncrypt(registrationMasterGrid?.[0]?.whereClause)
                : null,
              parameter:
                selectedTopSearchFilter?.where !== ''
                  ? {
                      searchParameter: searchInputValue,
                      searchWhere: selectedTopSearchFilter?.where,
                    }
                  : {},
            })
          );
        }
      }
    }
  };
  return <TableComponent />;
};

export default MainTable;

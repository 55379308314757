import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import DataTable from '../../common/components/DataTable';
import FilterView from '../../common/components/FilterView';
import { IDataTableColumn } from '../../common/types/dataTable';
import { AppDispatch, RootState } from '../../store';
import { useEffect, useMemo, useState } from 'react';

import queryString from 'query-string';
import {
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import OblDetails from '../../common/components/OblDetails';
import { AESDecrypt, AESEncrypt } from '../../../encrypt-util';
import {
  IConfigsDefinition,
} from '../../utils/columnConfig';

import { getColumnViews } from '../../common/actions/dataTable';
import {
  updateParams,
  updateSortStateDataTable,
} from '../../common/components/DataTable/slices/DataTable';
import { getDashboardData } from '../dashboard/actions/dashboard';
import { updateCurrentPageReportDashboard } from '../dashboard/slices/dashboardSlice';
import { updateDashbaordName } from '../../common/slices/navbarTop';

import moment from 'moment';
import DashboardContainer from '../../../components/common/DashboardContainer';
import useDataCountry from '../../../hooks/useDataCountry';
import {
  selectReportInstruction,
  toggleColumnSettingsPayDash,
} from './reportSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import useReportGridName, { reportMap } from './useReportGridName';
import { getReportTypes } from '../dashboard/actions/instruction';
import { useAmountFormatter } from '../../../hooks/useAmountFormatter';
import { useGenerateColumnConfig } from '../../../hooks/useGenerateColumnConfig';

const gridSort = 'bl_no desc';
const ReportDashboard = () => {
  const { blNum } = useParams();

  const navigate = useNavigate();
  const reportList = useSelector(
    (state: RootState) => state.reportDashboard.reportList
  );

  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [columns, setColumns] = useState<IDataTableColumn[]>([]);
  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });

  useEffect(() => {
    const localData = window.localStorage.getItem('@linerReportInstruct');

    if (localData && reportList.map((i: any) => i.value).includes(localData)) {
      dispatch(selectReportInstruction(localData));
    } else if (reportList.length >= 1) {
      dispatch(selectReportInstruction(reportList[0].value));
    } else {
      dispatch(selectReportInstruction(''));
    }
  }, [reportList]);

  const {reportGridName,reportGridFilterKey} = useReportGridName();
  const currentPage = useSelector(
    (state: RootState) => state.dashboard.currentPageReport
  );

  const data = useSelector(
    (state: RootState) => state.dashboard.reportDashboardList
  );

  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );
  const grids = useSelector((state: RootState) => state.dataTable.reportGrids);
  const [previousState, setPreviousState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: '{}',
    search: '',
    grids: grids?.[0]?.gridId,
  });
  const [currentState, setCurrentState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({
    filterState: '{}',
    search: '',
    grids: grids?.[0]?.gridId,
  });
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );

  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageReportDashboard(page));
  };
  const linerId = useSelector((state: RootState) => state.navbarTop.linerId);
  const topSearchState = useSelector(
    (state: RootState) => state.navbarTop.reportTopSearchState
  );
  const selectedConfigId = useSelector(
    (state: RootState) => state.dataTable.selectedConfigId
  );
  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );
  const searchInputValue = useSelector(
    (state: RootState) => state.navbarTop.searchInputValue
  );

  const dataCountry = useDataCountry();
  const generateColumnConfig = useGenerateColumnConfig()

  useEffect(() => {
    const dashboardDefinition = grids?.[0]?.columnConfigGrid || '';

    if (!dashboardDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(dashboardDefinition)),
        getParams,
      );

      const columns = configs?.columns?.map((c) => c);
      setConfigs(configs);
      setColumns(columns);
    } catch (e) {
      console.error('Column Error: ', e);
    }
  }, [grids, location]);

  useEffect(() => {
    dispatch(getReportTypes({ partnerCountry: dataCountry, linerId: 1 }));
  }, [dataCountry]);

  useEffect(() => {
    dispatch(updateDashbaordName(reportGridName));
  }, [reportGridName]);
  const filterStateLocal = filterState?.[reportGridFilterKey];
  const sort = (queryString.parse(location.search)?.sort as string)
    ?.split?.(',')
    ?.join?.(' ');

  useEffect(() => {
    if (location?.pathname && dataCountry && reportGridName && linerId) {
      dispatch(
        getColumnViews({
          gridName: reportGridName,
          country: dataCountry,
          linerId: linerId,
        })
      );
    }
    dispatch(updateParams({}));
    dispatch(
      updateSortStateDataTable({
        key: null,
      })
    );
  }, [dataCountry, reportGridName, reportList, linerId]);

  useEffect(() => {
    loadDashboard(false);
  }, [currentPage]);

  useEffect(() => {
    if (matchPath(location.pathname, '/reports')) loadDashboard(false);
  }, [location.pathname]);

  useEffect(() => {
    const currentState = {
      filterState: JSON.stringify(filterStateLocal),
      search: location.search,
      grids: grids?.[0]?.gridId,
    };
    if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
      setCurrentState({
        filterState: JSON.stringify(filterStateLocal),
        search: location.search,
        grids: grids?.[0]?.gridId,
      });
    }
  }, [filterStateLocal, location.search, grids]);

  useEffect(() => {
    if (JSON.stringify(previousState) !== JSON.stringify(currentState)) {
      loadDashboard(true);
      setPreviousState(currentState);
    }
  }, [currentState]);

  const loadDashboard = (filterUpdated: boolean) => {
    if (filterUpdated) setCurrentPage(1);

    if (filterStateLocal) {
      if (Object.keys(filterStateLocal)?.length > 0) {
        const filterApiState: any = {};

        const filterKeys = Object.keys(filterStateLocal);

        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              filterStateLocal?.[k]?.from || '',
              filterStateLocal?.[k]?.to || '',
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === 'string'
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined || undefined;
          }
        }
        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any = selectedTopSearchFilter?.id && searchInputValue.trim() != ''
          ? { [selectedTopSearchFilter?.id]: [searchInputValue.trim()] }
          : {};

          if (grids[0]) {
            let timeFlag = '';
            let timeDiff = 0;
            let dateRange: any = {};

            const newdata = grids[0].configGroup?.find(
              (c: any) => c.userGridId == selectedConfigId?.[reportGridFilterKey]
            )?.columnConfigGrid;
            if (newdata) {
              timeFlag = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'invdt'
              )?.filterState;
              timeDiff = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'invdt'
              )?.timeDiff;
              dateRange = JSON.parse(newdata).find(
                (item: any) => item.accessor == 'invdt'
              )?.dateRange;
            }
            if (filterApiState.invdt && timeFlag == 'day' && dateRange) {
              filterApiState.invdt[1] = moment()
                .subtract(Math.abs(dateRange.to), 'days')
                .endOf('day')
                .toISOString();
              filterApiState.invdt[0] = moment()
                .subtract(Math.abs(dateRange.from), 'days')
                .startOf('day')
                .toISOString();
            }
          }
          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }
          if (
            (profileDetails.partnerType === 'customer' &&
              profileDetails.partnerId &&
              grids?.[0]?.whereClause) ||
            profileDetails.partnerType === 'liner'
          ) {
            setTimeout(() => {
              dispatch(
                getDashboardData({
                  gridName: reportGridName,
                  payload: {
                    fields: /*  SelectedViewFields?.fields || */ [],
                    filters:
                      !selectedTopSearchFilter ||
                      selectedTopSearchFilter?.where == ''
                        ? finalFilterApiState
                        : {},

                    size: 20,
                    sorts: sort ? [sort] : [gridSort],
                    page: filterUpdated ? 1 : currentPage,
                  },
                  dataCountry: dataCountry,
                  partnerId:
                    profileDetails.partnerType === 'customer'
                      ? profileDetails.partnerId
                      : null,
                  where: grids?.[0]?.whereClause
                    ? AESEncrypt(grids?.[0]?.whereClause)
                    : null,
                  parameter:
                    selectedTopSearchFilter?.where !== ''
                      ? {
                          searchParameter: searchInputValue,
                          searchWhere: selectedTopSearchFilter?.where,
                        }
                      : {},
                })
              );
            }, 250);
          }
        }
      } else {
        if (
          (profileDetails.partnerType === 'customer' &&
            profileDetails.partnerId &&
            grids?.[0]?.whereClause) ||
          profileDetails?.partnerType === 'liner'
        ) {
          setTimeout(() => {
            dispatch(
              getDashboardData({
                gridName: reportGridName,
                payload: {
                  fields: /* SelectedViewFields?.fields || */ [],
                  filters:
                    selectedTopSearchFilter?.where === '' ? topSearchState : {},
                  size: 20,
                  sorts: sort ? [sort] : [gridSort],
                  page: filterUpdated ? 1 : currentPage,
                },
                dataCountry: dataCountry,
                partnerId:
                  profileDetails.partnerType === 'customer'
                    ? profileDetails.partnerId
                    : null,
                where: grids?.[0]?.whereClause
                  ? AESEncrypt(grids?.[0]?.whereClause)
                  : null,
                parameter:
                  selectedTopSearchFilter?.where !== ''
                    ? {
                        searchParameter: searchInputValue,
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
              })
            );
          }, 250);
        }
      }
    } else {
      if (
        location.pathname.includes('/report') &&
        location?.state?.from == undefined
      ) {
        if (
          (profileDetails.partnerType === 'customer' &&
            profileDetails.partnerId &&
            grids?.[0]?.whereClause) ||
          profileDetails.partnerType === 'liner'
        ) {
          setTimeout(() => {
            dispatch(
              getDashboardData({
                gridName: reportGridName,
                payload: {
                  fields: /* SelectedViewFields?.fields || */ [],
                  filters:
                    selectedTopSearchFilter?.where == '' ? topSearchState : {},
                  size: 20,
                  sorts: sort ? [sort] : [gridSort],
                  page: filterUpdated ? 1 : currentPage,
                },
                dataCountry: dataCountry,
                partnerId:
                  profileDetails.partnerType === 'customer'
                    ? profileDetails.partnerId
                    : null,
                where: grids?.[0]?.whereClause
                  ? AESEncrypt(grids?.[0]?.whereClause)
                  : null,
                parameter:
                  selectedTopSearchFilter?.where !== ''
                    ? {
                        searchParameter: searchInputValue,
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
              })
            );
          }, 250);
        }
      }
    }
  };

  const showColumnSettings = useSelector(
    (state: RootState) => state.reportDashboard.showColumnSettings
  );

  const getParams = () => {
    return {
      blid: blNum ? AESDecrypt(blNum) : '',
      pmtmode: blNum ? AESDecrypt(blNum) : '',
    };
  };

  useEffect(() => {
    dispatch(updateParams({}));
    dispatch(
      updateSortStateDataTable({
        key: null,
      })
    );
  }, [dataCountry]);

  const sideWidth =
    Object.entries({
      [`/report`]: 0,
    }).find(([path, _]) => matchPath(path, location.pathname))?.[1] ?? 9;

  return (
    <DashboardContainer
      sideWidth={sideWidth}
      middleChildren={
        location.pathname.split('/')[1] == 'blsliderinvoice' ? (
          <OblDetails
            blslider={`/blsliderinvoice/${location.pathname.split('/')[1]}`}
          />
        ) : (
          <div className={`flex-1 h-100 smoothDiv w-100`}>
            <Outlet />
          </div>
        )
      }
    >
      <FilterView
        loadList={loadDashboard}
        showReportDropdown={true}
        showUploadButton
        showExport={true}
        showColumnSettings={true}
        dataLoading={false}
        toggleColumnSettings={toggleColumnSettingsPayDash}
        columnSettingsPersistKey='@odex/report/v1'
        uniqueFilterKey={reportGridFilterKey}
        configViews={
          grids?.find?.((g) => g?.gridName === reportGridName)?.configGroup || []
        }
        showRightDiv={!!matchPath(location.pathname, '/report') && !!reportGridName}
        dashboardName={reportGridName}
        data={data}
        gridDef={grids}
      />
      {!reportGridName && (
        <div className='flex-1 d-flex h-100 align-items-center justify-content-center'>
          <span
            className='f-1'
            style={{
              userSelect: 'none',
            }}
          >
            <FontAwesomeIcon icon={faXmarkCircle} /> No Reports Found
            <br />
            <br />
            <br />
            <br />
          </span>
        </div>
      )}
      {reportGridName && columns?.length > 0 && (
        <DataTable
          data={data?.result || null}
          columns={columns}
          dataCountry={dataCountry || ''}
          sortable
          searchable={false}
          showColumnFilter={false}
          selection={false}
          perPage={20}
          pagination
          numberPagination
          maxPage={parseInt(data?.maxPage || '100')}
          hasNext={data?.hasNext}
          currentPage={currentPage}
          gridId={grids?.[0]?.gridId || 0}
          onChangePageNumber={(pageNumber) => setCurrentPage(pageNumber)}
          showExport={false}
          showColumnSettings={showColumnSettings}
          onHide={() => {
            dispatch(toggleColumnSettingsPayDash(false));
          }}
          columnSettingsPersistKey='@odex/report/v1'
          customSort={true}
          uniqueFilterKey={reportGridFilterKey}
          staticHiddenCols={configs?.staticHiddenCols || []}
          defaultHiddenCols={[]}
          tableMinHeight={400}
          gridName={reportGridName}
          dataLoading={false}
          configGroup={grids?.[0]?.configGroup || []}
        />
      )}
    </DashboardContainer>
  );
};

export default ReportDashboard;

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { RootState } from "../../../store";

export const getCountryList = createAsyncThunk(
    'quickPay/getCountryList',
    async (payload: any) => {
      try {
        const response = await axios.get(
          `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguageWithoutSession?language=EN&codeType=QUICK_COUNTRY&countryCode=US`
        );
        const options = response.data.map((item: any) => ({
          value: item.codeDesc,
          text: item.codeDesc,
          full: item.codeDesc
        }));

        return options;
      } catch (error) {
        console.error(error);
  
        return {};
      }
    }
  );


export const getCurrencyDDList = createAsyncThunk(
    'quickpay/getCurrencyDDList',
    async (payload:{country:string}) => {
      try {
        const response = await axios.get(
          `/api/user-management/v1/country/getCurrencyDDListWithoutSession?country=${payload.country}`
        );
        return response?.data;
      } catch (e) {
        const error = e as AxiosError;

        return null;
      }
    }
  );

  export const getQuickPayModeOptions = createAsyncThunk(
    'quickPay/getPymtModeOptions',
    async (payload: any) => {
      try {
        const response = await axios.get( 
          `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguageWithoutSession?language=EN&codeType=QUICK_PYMT_MODE&linerId=${payload.selectedLinerId}&countryCode=${payload.partnerCountry}`,
        );
  
        const pymtModeOptions = response.data.map((item: any) => ({
          value: item.codeId,
          text: item.codeDesc,
        }));
  
        return pymtModeOptions;
      } catch (e) {
        const error = e as AxiosError;
        return null;
      }
    }
  );

  export const getQuickPayLinerOptions = createAsyncThunk(
    'quickPay/linerOptions',
    async (payload: any) => {
      try {
        const response = await axios.get( 
          `/api/invoice-management/v1/mstCode/getMstCodeListByTypeAndLanguageWithoutSession?language=EN&codeType=QUICKPAY_LINERS&countryCode=${payload.partnerCountry}`,
        );
  
        const pymtModeOptions = response.data.map((item: any) => ({
          value: item.codeId,
          text: item.codeDesc,
        }));
  
        return pymtModeOptions;
      } catch (e) {
        const error = e as AxiosError;
        return null;
      }
    }
  );

  export const validateQuickPayInvoice = createAsyncThunk(
    'quickpay/validateInvoice',
    async (payload: any, {getState}:any) => {
      const state:RootState = getState()
      const {invoiceAmount, invoiceNo} = state?.quickpay?.data?.filter((x:any)=>x?.rowNumber == payload.rowNumber)[0]
      if( invoiceAmount == null 
          || invoiceAmount == 0
          || invoiceNo == '' 
          || state.quickpay.currency == ""
      )return null


      try {
        const response = await axios.post(
          `/api/invoice-management/v1/payment/validateInvoiceForQuickPay?country=${payload.country}`,
          {
            invoiceId: null,
            invoiceNo: invoiceNo || null,
            invoiceAmount: invoiceAmount || null,
            invoiceCurrency: state.quickpay.currency || null,
          }
        );
        if (response?.data?.message){
          toast.error(response.data.message)
        }
        return {
          ...response?.data,
          rowNumber: payload.rowNumber,
        };
      } catch (e) {
        const error = e as AxiosError;
  
        console.error( error.message);
        toast.error(error.message, {
          theme: 'colored',
        });
        return error;
      }
    }
  );

  export const getPlatformFees = createAsyncThunk(
    'quickPay/getPaymentFees',
    async (payload: any, {getState}:any) => {
      const state:RootState = getState()
      try {
        const response = await axios.get( 
          `/api/payment-gateway/v1/platform/charges?pmtAmount=${payload.amount}&countryCode=${state.quickpay.country}&currency=${payload.ccy}&linerId=${state.quickpay.linerId}&pmtMode=${state.quickpay.pymtMode}`,
        );
  
        return response.data
      } catch (e) {
        const error = e as AxiosError;
        return null;
      }
    }
  );
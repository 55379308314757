import useDataCountry from "./useDataCountry";

export function useAmountFormatter(
    currency: string = 'USD',
    invalidResponse: string = 'Invalid Number',
) {
    const dataCounty = useDataCountry()
    const decimals: Record<string, number> = {
        'JOD': 3
    }
    return (amount: string | number, options?:{ccy?: string|null, round?: number}) => {
        const {ccy, round} = options?? {ccy: null, round:undefined}
        const numericValue = +amount
        const stringValue = `${round?numericValue.toFixed(round):numericValue}`
        
        if (isNaN(numericValue)) {
            return invalidResponse;
        }
        const [numberPart, decimalPart] = stringValue.split('.')
        return `${numberPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }.${(decimalPart ?? '').padEnd(decimals[ccy??currency] ?? 2, '0')
            }`
    }
}

export function roundingPrecision(nums:Array<number>){
    if (nums?.length == 0) return 0
    return Math.max(...nums.map((i)=>i?.toString()?.split('.')?.[1]?.length??0))
}
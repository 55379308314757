import { useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router';
import { RootState } from '../app/store';
import { useBlDashName, useInstDashName, useInvDashName } from './useDashName';

/**
 * Returns onBack function that on call will navigate to the previous
 * Card
 * @returns funnction
 */
export function useBackNavigation(inner=true) {
  const navigate = useNavigate();
  const location = useLocation();
  const { blNum, custId } = useParams();
  const blDashboardName = useBlDashName()
  let datatableState: string = useSelector(
    (state: RootState) => state.navbarTop.selectedDashbaordName
  );

  const paths = {
    BL_DASHBOARD_CUSTOMER: {
      home: '/home',
      document: `/bl/${encodeURIComponent(blNum ?? '')}`
    },
    BL_DASHBOARD_LINER: {
      home: '/home',
      document: `/bl/${encodeURIComponent(blNum ?? '')}`
    },
    INVOICE_DASHBOARD_LINER: { 
      home: '/payment', 
      document: `/payment/${encodeURIComponent(blNum ?? '')}`,
      standalone: '/payment/addInvoice' 
    },
    INVOICE_DASHBOARD_CUSTOMER: { 
      home: '/payment', 
      document: `/payment/${encodeURIComponent(blNum ?? '')}`,
      standalone: '/payment/addInvoice' 
    },
    INSTRUCTION_DASHBOARD_LINER: {
      home: '/instruction',
      document: `/instruction/${encodeURIComponent(blNum ?? '')}`,
    },
    INSTRUCTION_DASHBOARD_CUSTOMER: {
      home: '/instruction',
      document: `/instruction/${encodeURIComponent(blNum ?? '')}`,
    },
    USER_DASHBOARD: { home: '/user' },
    STANDING_INSTRUCTION: { home: '/standing' },
    PAYMENT_DASHBOARD: {home: '/paymentDashboard'},
    CUSTOMER_DASHBOARD: {
      home: '/mappingdashboard',
      customer: `/mappingdashboard/customerdetails/${custId}`
    }
  };
  
  const onBack = () => {
    const search = location.search
    if (!paths.hasOwnProperty(datatableState)) {
      datatableState = blDashboardName
    }
    
    if(inner){
      const outer:string = (()=>{
      if(location.pathname.includes('addInvoice'))return 'standalone'

      if (location.pathname.includes('/bl/') &&
          !matchPath('/bl/:blNum/', location.pathname))return 'document'
      
    
      if (['edituser', 'addcode'].some((i)=>location.pathname.includes(i))
      )return 'customer'

      return 'home'
    })()
      
      navigate((paths as any)[datatableState][outer]+search)
      return
    }

    navigate((paths as any)[datatableState]['home']+search)
    
  };
  return onBack;
}

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Divider from '../../../components/common/Divider';
import PayableAmount from './PayableAmount';
import { Card } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useDispatch } from 'react-redux';
import { getQuickPayModeOptions } from './actions/quickPayActions';
import { useLoading } from '../../../middleware/loadingMiddleware';
import App from '../blPayment/app';
import { setQuickPaymentMode } from './quickPaySlice';
import Lottie from 'lottie-react';
import infiniteLoop from '../../../assets/img/animated-icons/infinite-loop.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';

const QuickPayment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userData = location.state;
  const invData = useSelector((state: RootState) => state.quickpay.data);
  const currency = useSelector((state: RootState) => state.quickpay.currency)
  const pymtModeList = useSelector(
    (state: RootState) => state.quickpay.pymtModeList
  );
  const pymtMode = useSelector((state: RootState) => state.quickpay.pymtMode)
  const pymtModeLoading = useLoading(['quickPay/getPymtModeOptions']);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const platformChargeConfigId = useSelector((state: RootState) => state.quickpay.platformChargeConfigId)
  useEffect(() => {
    if (
      !userData ||
      !currency ||
      invData.findIndex((i) => !i.valid) != -1
    )
      navigate('/quickPay');

    else
      dispatch(
        getQuickPayModeOptions({
          selectedLinerId: userData.liner,
          partnerCountry: userData.country,
        })
      );
  }, []);
  return (
    <div>
      <div>
        <div className='d-flex align-items-center'>
          <FontAwesomeIcon
            style={{ position: 'relative', left: '0' }}
            icon={faArrowAltCircleLeft}
            className='cursor-pointer fa-lg me-2'
            onClick={() => navigate(-1)}
          />
          <h3 className='fs-1 mt-1'>Payment Details </h3>
        </div>
      </div>
      <Divider></Divider>
      <Card.Body>
        <PayableAmount
          selectedAmount={invData.map((x) => ({
            ccy: currency,
            amount: x.invoiceAmount,
            invcurr: currency,
          }))}
        />
        <Divider></Divider>
        <Form.Label className='fs--1'>{t('resource_2:paymentMode')}</Form.Label>

        <Form.Select
          aria-label='Payment Mode'
          className={`fs--1 ${pymtModeLoading ? 'loadingPulse' : ''}`}
          disabled={pymtModeLoading || pymtModeList?.length < 2}
          value={pymtMode ?? ''}
          onChange={(e) => { dispatch(setQuickPaymentMode(e.target.value)) }}
        >
          {pymtModeList.map((option: any) => (
            <option
              className='text-dark'
              key={option.value}
              value={option.value}
            >
              {option.text}
            </option>
          ))}
        </Form.Select>

        <div className='w-100 justify-content-center d-flex m-3'>
          <div>
            <p />
            {(['7', '8', 7, 8].includes(pymtMode ?? '')) &&
              userData.emailID && userData.liner && userData.country
              && invData && platformChargeConfigId && !pymtModeLoading ?

              <App payload={{
                invoiceIds: invData.map((item) => item.invoiceId),
                email: userData.emailID,
                linerId: userData.liner,
                countryCode: userData.country,
                pymtMode: pymtMode,
                platformChargeConfigId: platformChargeConfigId
              }} quickPay={true} /> : <Lottie
                animationData={infiniteLoop}
                loop={true}
                style={{ height: '80px', width: '80px' }}
              />}
          </div>
        </div>
      </Card.Body>
    </div>
  );
};

export default QuickPayment;

import { Card, Button, Dropdown } from 'react-bootstrap';
import { IDataTableColumn } from '../../common/types/dataTable';
import DataTable from '../../common/components/DataTable';
import { data } from './data/standingInstructionTable';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dataTableBgImg from '../../../../src/assets/img/illustrations/cornerRight-1.png';
import {
  faCheckCircle,
  faGear,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import {
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import InstructionForm from './InstructionForm';
import { Link } from 'react-router-dom';
import FilterView from '../../common/components/FilterView';
import Header from '../../common/components/DetailsViewHeader';
import queryString from 'query-string';
import { updateBackNavUrl } from '../../common/slices/documentViewSlice';
import { useDispatch } from 'react-redux';
import {
  IConfigsDefinition,
} from '../../utils/columnConfig';
import { updateCurrentPageStandingInst } from '../dashboard/slices/dashboardSlice';
import {
  toggleColumnSettingsDashboard,
  updateRowColumns,
} from './slices/standingInstructionTable';
import quieryString from 'query-string';
import { updateDashbaordName } from '../../common/slices/navbarTop';
import { getColumnViews } from '../../common/actions/dataTable';
import { updateParams } from '../../common/components/DataTable/slices/DataTable';
import { AESDecrypt, AESEncrypt } from '../../../encrypt-util';
import { getDashboardData } from '../dashboard/actions/dashboard';
import OdexSpinner from '../../common/components/OdexSpinner';
import { updateInstructionID } from '../blDetails/slices/documentHistorySlice';
import { useAmountFormatter } from '../../../hooks/useAmountFormatter';
import { useGenerateColumnConfig } from '../../../hooks/useGenerateColumnConfig';

const dashboarName = 'STANDING_INSTRUCTION';
const uniqueFilterKey = 'standingInst';
const defaultSort = 'standing_instruction_id desc';

const StandingInstructionTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const { userID, encryptdoc } = useParams();

  const [previousState, setPreviousState] = useState<{
    filterState: string | null;
    search: string | null;
    grids: any[] | null;
  }>({ filterState: null, search: null, grids: null });

  const [configs, setConfigs] = useState<IConfigsDefinition>({
    columns: [],
    staticHiddenCols: [],
    defaultHiddenCols: [],
  });
  const onDashboard = location.pathname === '/standing';
  const rowColumns = {
    mainTableClass: onDashboard ? 'col-lg-12' : 'col-lg-3',
    outletClass: onDashboard ? 'd-none' : 'col-lg-9',
  };

  const [columns, setColumns] = useState<IDataTableColumn[]>([]);
  const standingInstructionDetails = useSelector(
    (state: RootState) => state.standingInstructionList
  );
  const profileDetails = useSelector(
    (state: RootState) => state.userProfile.profile
  );
  const showColumnSettings = useSelector(
    (state: RootState) => state.standingInstructionList.showColumnSettings
  );
  const sortState = useSelector((state:RootState) => state.dataTable.sortState);
  const dataLoading = useSelector(
    (state: RootState) => state.standingInstructionList.dataLoading
  );
  const loading = useSelector(
    (state: RootState) => state.dashboard.dataLoading
  );
  const grids = useSelector(
    (state: RootState) => state.dataTable.standingInstructionGrids
  );

  const data = useSelector(
    (state: RootState) => state.dashboard.standingInstructionList
  );
  const filterState = useSelector(
    (state: RootState) => state.dataTable.filterState
  );

  const currentPageStandingInst = useSelector(
    (state: RootState) => state.dashboard.currentPageStandingInst
  );

  const selectedTopSearchFilter = useSelector(
    (state: RootState) => state.navbarTop.selectedTopSearchFilter
  );
  const searchInputValue = useSelector(
    (state: RootState) => state.navbarTop.searchInputValue
  );
  const standingInstSearchState = useSelector(
    (state: RootState) => state.navbarTop.standingInstSearchState
  );

  const loadingGridConfigs = useSelector(
    (state: RootState) => state.dataTable.loadingGridConfigs
  );

  function useDebouncedEffect(effect: any, deps: any, delay: any) {
    const callback = useRef<any>();
    
    useEffect(() => {
      callback.current = effect;
    }, [effect]);
  
    useEffect(() => {
      const handler = () => {
        if (callback.current) {
          callback.current();
        }
      };
  
      const timer = setTimeout(handler, delay);
      
      return () => clearTimeout(timer);
    }, [...deps, delay]);
  }

  const setCurrentPage = (page: number) => {
    dispatch(updateCurrentPageStandingInst(page));
  };

  const onHideColumnSettings = () => {
    dispatch(toggleColumnSettingsDashboard(false));
  };

  const getParams = () => {
    dispatch(
      updateInstructionID(AESDecrypt(location?.pathname?.split?.('/')?.[4]))
    );
    return {standing_instruction_id:AESDecrypt(encryptdoc)};
  };

  const filterStateLocal = filterState?.[uniqueFilterKey];
  const sort = (quieryString.parse(location.search)?.sort as string)
    ?.split(',')
    ?.join(' ');

  const generateColumnConfig = useGenerateColumnConfig()

  useEffect(() => {
    const standingInstDefinition = grids?.[0]?.columnConfigGrid || '';
    if (!standingInstDefinition) return;

    try {
      const configs = generateColumnConfig(
        JSON.parse(JSON.parse(standingInstDefinition)),
        getParams,
      );

      const columns = configs?.columns?.map((c) => c);
      setConfigs(configs);
      setColumns(columns);
    } catch (e) {
      console.error('Column Error: ', e);
    }
  }, [grids, location]);

  // Getting the user country
  const linerDataCountry = useSelector(
    (state: RootState) => state.userProfile.linerDataCountry
  );
  const storedUserCountry = JSON.parse(
    window.localStorage.getItem('@user-selected-datacountry') || '{}'
  );
  const storedCountry =
    storedUserCountry?.[0]?.loginId === profileDetails.email
      ? storedUserCountry?.[0]?.country
      : null;
  const dataCountry =
    storedCountry ?? linerDataCountry ?? profileDetails.partnerCountry ?? null;
    const linerId = useSelector((state: RootState) => state.navbarTop.linerId);
  useEffect(() => {
    dispatch(updateDashbaordName(dashboarName));
  }, []);

  useEffect(() => {
    if (
      matchPath(
        '/standing/:blNum/:encrypt/:standing_instruction_id',
        location.pathname
      ) ||
      matchPath('/standing/:encrypt', location.pathname)
    ) {
      dispatch(
        updateRowColumns({
          mainTableClass: 'col-lg-3',
          outletClass: 'col-lg-9',
        })
      );
    } else {
      dispatch(
        updateRowColumns({
          mainTableClass: 'col-lg-12',
          outletClass: 'd-none',
        })
      );
    }
  }, [location.pathname]);

  useEffect(() => {
    if (dataCountry && linerId) {
      dispatch(
        getColumnViews({ gridName: dashboarName, country: dataCountry, linerId : linerId })
      );
    }
    dispatch(updateParams({}));
  }, [dataCountry, linerId]);

  useEffect(() => {
    loadStandingDashboard(false);
  }, [currentPageStandingInst]);

  // useEffect(() => {
  //   loadStandingDashboard(true);
  // }, [location.pathname, dataCountry, linerId]);

  useDebouncedEffect(() => {
    loadStandingDashboard(true);
  }, [location.pathname, dataCountry, linerId], 700);


  useEffect(() => {
    const currentStateValue = {
      filterState: JSON.stringify(filterStateLocal),
      search: location.search,
      grids: grids,
    };

    if (JSON.stringify(previousState) !== JSON.stringify(currentStateValue)) {
      loadStandingDashboard(true);
      setPreviousState(currentStateValue);
    }
  }, [filterStateLocal, location.search, grids, linerId]);

  const loadStandingDashboard = (filterUpdated: boolean) => {
    if (filterUpdated) setCurrentPage(1);

    if (filterStateLocal) {
      if (Object.keys(filterStateLocal)?.length > 0) {
        const filterApiState: any = {};
        const filterKeys = Object.keys(filterStateLocal);

        for (let k of filterKeys) {
          if (filterStateLocal?.[k]?.from) {
            filterApiState[k] = [
              filterStateLocal?.[k]?.from || '',
              filterStateLocal?.[k]?.to || '',
            ].filter((i) => i);
          } else if (filterStateLocal?.[k]?.from !== undefined) {
            filterApiState[k] = undefined;
          } else {
            filterApiState[k] = filterStateLocal?.[k]
              ? typeof filterStateLocal?.[k] === 'string'
                ? [filterStateLocal?.[k]]
                : filterStateLocal?.[k]
              : undefined || undefined;
          }
        }
        if (Object.keys(filterApiState)?.length > 0) {
          const finalFilterApiState: any = {};

          for (let i of Object.keys(filterApiState)) {
            if (filterApiState[i]) {
              finalFilterApiState[i] = filterApiState[i];
            }
          }
          if (
            (profileDetails.partnerType === 'customer' &&
              profileDetails.partnerId &&
              grids?.[0]?.whereClause) ||
            profileDetails.partnerType === 'liner'
          ) {
            setTimeout(()=>{dispatch(
              getDashboardData({
                gridName: dashboarName,
                payload: {
                  fields: /*  SelectedViewFields?.fields || */ [],
                  filters: finalFilterApiState ?? {},
                  size: 20,
                  sorts: sortState && sortState?.key!=null && sortState?.value!=null ? [`${sortState?.key} ${sortState?.value}`] : sort ? [sort] : [defaultSort],
                  page: currentPageStandingInst,
                },
                dataCountry: dataCountry,
                partnerId:
                  profileDetails.partnerType === 'customer'
                    ? profileDetails.partnerId
                    : null,
                where: grids?.[0]?.whereClause
                  ? AESEncrypt(grids?.[0]?.whereClause)
                  : null,
                parameter:
                  selectedTopSearchFilter?.where !== ''
                    ? {
                        searchParameter: searchInputValue,
                        searchWhere: selectedTopSearchFilter?.where,
                      }
                    : {},
              })
            );},250)
            
          }
        }
      } else if (
        (profileDetails.partnerType === 'customer' &&
          profileDetails.partnerId &&
          grids?.[0]?.whereClause) ||
        profileDetails?.partnerType === 'liner'
      ) {
        setTimeout(()=>{dispatch(
          getDashboardData({
            gridName: dashboarName,
            payload: {
              fields: /* SelectedViewFields?.fields || */ [],
              filters:
                selectedTopSearchFilter?.where === ''
                  ? standingInstSearchState
                  : {},
              size: 20,
              sorts: sortState && sortState?.key!=null && sortState?.value!=null ? [`${sortState?.key} ${sortState?.value}`] : sort ? [sort] : [defaultSort],
              page: currentPageStandingInst,
            },
            dataCountry: dataCountry,
            partnerId:
              profileDetails.partnerType === 'customer'
                ? profileDetails.partnerId
                : null,
            where: grids?.[0]?.whereClause
              ? AESEncrypt(grids?.[0]?.whereClause)
              : null,
            parameter:
              selectedTopSearchFilter?.where !== ''
                ? {
                    searchParameter: searchInputValue,
                    searchWhere: selectedTopSearchFilter?.where,
                  }
                : {},
          })
        );},250)
        
      }
    } else if (
      !location.pathname.includes('blsliderinvoice/') &&
      location.pathname.includes('/payment') &&
      location?.state?.from == undefined
    ) {
      if (
        (profileDetails.partnerType === 'customer' &&
          profileDetails.partnerId &&
          grids?.[0]?.whereClause) ||
        profileDetails.partnerType === 'liner'
      ) {
        setTimeout(()=>{dispatch(
          getDashboardData({
            gridName: dashboarName,
            payload: {
              fields: /* SelectedViewFields?.fields || */ [],
              filters:
                selectedTopSearchFilter?.where == ''
                  ? standingInstSearchState
                  : {},
              size: 20,
              sorts: sortState && sortState?.key!=null && sortState?.value!=null ? [`${sortState?.key} ${sortState?.value}`] : sort ? [sort] : [defaultSort],
              page: currentPageStandingInst,
            },
            dataCountry: dataCountry,
            partnerId:
              profileDetails.partnerType === 'customer'
                ? profileDetails.partnerId
                : null,
            where: grids?.[0]?.whereClause
              ? AESEncrypt(grids?.[0]?.whereClause)
              : null,
            parameter:
              selectedTopSearchFilter?.where !== ''
                ? {
                    searchParameter: searchInputValue,
                    searchWhere: selectedTopSearchFilter?.where,
                  }
                : {},
          })
        );},250)
        
      }
    }
  };

  return (
    <div>
      <Card className='w-100' style={{ height: '89vh' }}>
        {/* <div className='bg-holder bg-card odex-bldetails-img-r' /> */}

        <div
          className='bg-holder bg-card'
          style={{
            backgroundImage: `url(${dataTableBgImg})`,
            borderTopRightRadius: '0.375rem',
            borderBottomRightRadius: '0.375rem',
          }}
        />

        <Card.Body className='p-0 h-100'>
          <div className='row g-0 w-100 h-100'>
            <div className={`${rowColumns.mainTableClass}`}>
              <div className=' row'>
                <div className='d-flex flex-column justify-content-between align-items-start w-100 h-100'>
                  <div className='d-flex flex-column justify-content-start align-items-start w-100 h-100'>
                    <div className='row g-0 col-transition-animation h-100 w-100'>
                      <div className={'d-flex flex-column w-100'}>
                        <FilterView
                          loadList={loadStandingDashboard}
                          showColumnSettings={location.pathname === '/standing'}
                          showRightDiv={location.pathname === '/standing'}
                          toggleColumnSettings={toggleColumnSettingsDashboard}
                          configViews={
                            grids?.find?.((g) => g?.gridName === dashboarName)
                              ?.configGroup || []
                          }
                          showExport={location.pathname === '/standing'}
                          uniqueFilterKey={uniqueFilterKey}
                          data={data}
                          columns={columns}
                          dataLoading={dataLoading}
                          columnSettingsPersistKey='@odex/user/v1'
                          dashboardName={dashboarName}
                          gridDef={grids}
                          showAddInstruct={location.pathname === '/standing'}

                          //data={userDetails?.data?.userData}
                        />
                        <DataTable
                          //data={userDetails.data.list}
                          data={data?.result || null}
                          columns={columns}
                          dataCountry={dataCountry || ''}
                          gridName={dashboarName}
                          sortable
                          searchable={false}
                          showColumnFilter={false}
                          selection={false}
                          perPage={20}
                          hasNext={data?.hasNext}
                          currentPage={currentPageStandingInst}
                          gridId={grids?.[0]?.gridId || 0}
                          onChangePageNumber={(pageNumber) =>
                            setCurrentPage(pageNumber)
                          }
                          pagination
                          showExport={false}
                          showColumnSettings={showColumnSettings}
                          onHide={onHideColumnSettings}
                          uniqueFilterKey={uniqueFilterKey}
                          staticHiddenCols={configs?.staticHiddenCols || []}
                          tableMinHeight={400}
                          dataLoading={false}
                          customSort={true}
                          numberPagination
                          previous={
                            standingInstructionDetails?.data?.instructionData
                              ?.previous || ''
                          }
                          next={
                            standingInstructionDetails?.data?.instructionData
                              ?.next || ''
                          }
                          shortPrepend='stanfing_'
                          defaultHiddenCols={configs?.defaultHiddenCols || []}
                          configGroup={
                            //  grids?.find?.((g) => g?.gridId === 2)?.configGroup || []
                            grids?.find?.(
                              (g) => g?.gridName === 'STANDING_INSTRUCTION'
                            )?.configGroup || []
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Card
              className={`${standingInstructionDetails.rowColumns.outletClass} h-100`}>
              <Card.Body className='zindex5 h-100 overflow-auto m-0 p-0'>
                <Outlet />
              </Card.Body>
            </Card>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default StandingInstructionTable;

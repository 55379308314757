/**
 * Editable invoice details table to be shown on the quickpay screen
 */

import { Button, Form } from "react-bootstrap";
import DataTable from "../../common/components/DataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { useDispatch } from "react-redux";
import { addQuickPayRow, removeQuickPayRow, setQuickCurrency, setQuickPayRow } from "./quickPaySlice";
import { useLoading } from "../../../middleware/loadingMiddleware";
import { useEffect } from "react";
import { getCurrencyDDList, validateQuickPayInvoice } from "./actions/quickPayActions";


export default function QuickPayTable({ country }: any) {

    const { t } = useTranslation()
    const data = useSelector((state: RootState) => state.quickpay.data)
    const dispatch = useDispatch<AppDispatch>()
    const validationLoading = useLoading(['quickpay/validateInvoice'])
    const currencyLoading = useLoading(['quickPay/getCountryList'])
    const currencyList = useSelector((state: RootState) => state.quickpay.currencyList)
    const currency = useSelector((state: RootState) => state.quickpay.currency)

    useEffect(() => {
        if (country) dispatch(getCurrencyDDList({ country: country }))
    }, [country])

    const invoiceTableColumnConfig = [
        {
            accessor: 'rowNumber',
            Header: 'rowNumber',
        },
        {
            accessor: 'invoiceId',
            Header: 'invoiceId',
        },
        {
            accessor: 'invoiceNo',
            Header: t('resource_2:invoiceNo'),
            width: 150,
            maxWidth: 180,
            Cell: (rowData: any) => {
                const { invoiceNo, rowNumber, error, valid } = rowData.row.original;
                return (
                    <input
                        type='text'
                        className={`fs--2 form-control  ${error.invoiceNo ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}
                        ${!valid && validationLoading ? 'loadingPulse' : ''}`}
                        defaultValue={invoiceNo || ''}
                        disabled={validationLoading}
                        onBlur={(e) => {
                            dispatch(setQuickPayRow(
                                {
                                    rowNumber,
                                    invoiceNo: e.target.value.trim()
                                }
                            ))
                            dispatch(validateQuickPayInvoice({ country, rowNumber }))
                        }}
                    />
                );

            },
        },
        {
            accessor: 'invoiceAmount',
            Header: t('resource_1:amount'),
            width: 140,
            maxWidth: 140,
            Cell: (rowData: any) => {
                const { invoiceAmount, rowNumber, error, valid } = rowData.row.original;
                return (
                    <input
                    type="number"
                        className={`fs--2 form-control ${error.invoiceAmount ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}
                        ${!valid && validationLoading ? 'loadingPulse' : ''}`}
                        defaultValue={invoiceAmount || null}
                        disabled={validationLoading}
                        onBlur={(e) => {
                            dispatch(setQuickPayRow(
                                {
                                    rowNumber,
                                    invoiceAmount: +e.target.value < 0 ? 0 : +e.target.value
                                }
                            ))
                            dispatch(validateQuickPayInvoice({ country, rowNumber }))
                        }}
                    />
                );
            },
        },
        {
            accessor: 'remove',
            Header: '',
            width: 40,
            maxWidth: 40,
            Cell: (rowData: any) => {
                const { remove, rowNumber } = rowData.row.original;
                return (
                    <Button
                        variant='falcon-warning'
                        style={{ background: 'transparent', border: 'none', boxShadow: 'none', padding: '0px' }}
                        disabled={validationLoading}
                        onClick={() => {
                            dispatch(removeQuickPayRow({ rowNumber }))
                        }}
                    >
                        <FontAwesomeIcon icon={faTrash} size='sm' />
                    </Button>
                );
            },
            disableSort: true,
        },
        {
            accessor: 'message',
            Header: '',
            width: 1,
            maxWidth: 1,
            Cell: (rowData: any) => {
                const { rowNumber, message } = rowData.row.original;
                return <span className='mandatory'>{message}</span>;
            },
        },
    ];

    return <div className='col-lg-12 mt-2 mb-2 demmurageTable d-flex flex-column'>

        <Form.Group className="mb-3 col-lg-6">
            <span className='mandatory'>*</span>
            <Form.Label>Currency</Form.Label>
            <Form.Select
                value={currency || ''}
                className={`fs--1 form-control form-select ${currencyLoading ? 'loadingPulse' : ''}`}
                disabled={currencyLoading || validationLoading || (currencyList?.length ?? 0) < 2}
                onChange={(e) => {
                    dispatch(setQuickCurrency(e.target.value))
                    data.forEach(({ rowNumber }) => {
                        dispatch(validateQuickPayInvoice({ country, rowNumber }))
                    })
                }}
            >
                {
                    currencyList.map((r: any) => {
                        return (
                            <>
                                <option value={r?.currencyCode} key={r?.currencyShortName}>
                                    {r?.currencyShortName}
                                </option>
                            </>
                        )
                    })
                }
            </Form.Select>
        </Form.Group>

        <Button
            className='filters-header-odex-md btn btn-falcon-warning p-0 mb-2'
            variant='falcon-warning'
            style={{
                width: '28px',
                height: '28px',
                marginRight: '10px',
            }}
            size='sm'
            disabled={validationLoading}
            onClick={() => { dispatch(addQuickPayRow()) }}>
            <FontAwesomeIcon icon={faPlus} size='sm' />
        </Button>

        <DataTable
            gridName='STANDALONE'
            columns={invoiceTableColumnConfig}
            data={data}
            shortPrepend='qp_'
            perPage={100}
            sortable={true}
            searchable={false}
            showColumnFilter={false}
            selection={false}
            pagination={false}
            seekPagination={false}
            showExport={false}
            localFilter={true}
            uniqueFilterKey='quickpay'
            autoHeight={true}
            maxHeight='21vh'
            tableMinHeight={300}
            staticHiddenCols={['rowNumber', 'invoiceId', 'message']}
        />
    </div>
}